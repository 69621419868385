













import { Component, Vue, Prop } from 'vue-property-decorator';
import Alerts from './Alerts.vue';
import SubMenu from './SubMenu.vue';
import { IMenuItem } from './Menu.vue';
import { RouteConfig } from 'vue-router';

@Component({
  components: {
    SubMenu,
    Alerts,
  },
})
export default class SubModule extends Vue
{
  /**
   * The route menu items.
   */
  public get routes(): RouteConfig[]
  {
    return this._getRoutes() || [];
  }

  /**
   * The dynamic sub menu items.
   */
  public get dynamicMenuItems(): IMenuItem[]
  {
    return this._getDynamicMenuItems() || [];
  }

  /**
   * Returns the sub routes for the menu.
   */
  protected _getRoutes(): RouteConfig[]
  {
    return [];
  }

  /**
   * Returns dynamic sub menu items.
   */
  protected _getDynamicMenuItems(): IMenuItem[]
  {
    return [];
  }
}
