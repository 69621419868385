







import { Component, Vue, Prop } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { IMenuItem } from './Menu.vue';
import { store, Getters } from '../store';

@Component
export default class SubMenu extends Vue
{
  @Prop()
  public readonly routes: RouteConfig[] | undefined;

  @Prop()
  public readonly additionalItems: IMenuItem[] | undefined;

  private get isLoggedIn(): typeof store[Getters.IS_LOGGED_IN]
  {
    return store[Getters.IS_LOGGED_IN];
  }

  /**
   * Returns the menu items for the navigation drawer.
   */
  public get menuItems(): IMenuItem[]
  {
    if (!this.routes)
    {
      return [];
    }

    return this.routes
      .filter(this.__filterRoute, this)
      .map(this.__createMenuItem, this)
      .concat(this.additionalItems || []);
  }

  private __filterRoute(route: RouteConfig): boolean
  {
    const meta = route.meta;
    if (meta && meta.hideTab === true)
    {
      return false;
    }

    if (!this.isLoggedIn && (!meta || meta.anonymous !== true))
    {
      return false;
    }

    if (meta && meta.userType)
    {
      const currentUserType = store[Getters.CURRENT_USER_LOGIN]!.userType;
      if (!this.$_.castArray(meta.userType).some(ut => ut === currentUserType))
      {
        return false;
      }
    }

    return true;
  }

  private __createMenuItem(route: RouteConfig): IMenuItem
  {
    const meta = route.meta || {};
    const resolvedRoute = this.$router.resolve(route);

    const name = meta.name || route.name;

    return {
      route: resolvedRoute.href,
      title: meta.title
        ? this.$root.$t(meta.title).toString()
        : (name ? (route.path ? this.$t(`${name}.title`).toString() : this.$root.$t(`module.${name}.title`).toString()) : ''),
      icon: meta.icon === false ? null : (meta.icon || name ? `$vuetify.icons.${(meta.icon || name).replace(/\./g, '_')}` : null),
    };
  }
}
